import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {UsacmContext} from "../App";
import {countWords, getConfLabel, sentenceCase, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {
  ABSTRACT_MAX_TEXT_WORDS,
  ABSTRACT_PRESENTATION_TYPE_ORAL,
  ABSTRACT_PRESENTATION_TYPE_POSTER,
  CONF_LABEL_KEY_SYMPOSIUM,
  CONF_LABEL_TYPE_ABSTRACT_STATUS,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF,
  SYMPOSIUM_STATUS_APPROVED
} from "../constants";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForAllFields, getErrorMessageForField} from "../utils/formUtils";
import {AuthorOrganizerEditor, TYPE_AUTHOR} from "../shared/AuthorOrganizerEditor";
import {getAbstract, updateAbstract} from "../api/AbstractApi";
import {hasPermission} from "../api/UserApi";
import {getLabelKeys, getLabelsOfType, getNameForAbstractStatus} from "../utils/displayUtils";
import {getSymposiaBrief} from "../api/SymposiumApi";
import {ForceUpdateButton} from "../shared/ForceUpdateButton";


export function AbstractEdit({abstractId, onClose, reloadAbstractList}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [abstract, setAbstract] = useState();
  const [status, setStatus] = useState('');
  const [moveRequested, setMoveRequested] = useState(false);
  const [adminNotes, setAdminNotes] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [presentationType, setPresentationType] = useState('');
  const [authors, setAuthors] = useState([]);
  const authorEditorRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const statusKeys = getLabelKeys(conf, CONF_LABEL_TYPE_ABSTRACT_STATUS);
  const statusLabels = getLabelsOfType(conf, CONF_LABEL_TYPE_ABSTRACT_STATUS);
  const symposiumLabel = getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM);
  const [confSymposia, setConfSymposia] = useState([]);
  const [symposiumId, setSymposiumId] = useState(-1);
  const canEditStatus = !abstract?.approved_email_sent && !abstract?.denied_email_sent && isStaffOrAdmin;

  function loadAbstractData() {
    getAbstract(abstractId, (code, data, errors) => {
      if (code === 200) {
        setAbstract(data);
        setStatus(data.status);
        setMoveRequested(data.move_requested || false);
        setAdminNotes(data.admin_notes || '');
        setTitle(data.title || '');
        setPresentationType(data.presentation_type || '');
        setAuthors(data.authors);
        setText(data.text || '');
        setSymposiumId(data.symposium_id);
      } else {
        setErrors(errors);
      }
    });
  }

  useEffect(() => {
    loadAbstractData();
    getSymposiaBrief((status, data, newErrors) => {
      if (status === 200) {
        const symposiumList = isStaffOrAdmin ? data : data.filter(s => isStaffOrAdmin ? true : s.status === SYMPOSIUM_STATUS_APPROVED);
        setConfSymposia(symposiumList);
      }
    });
  }, [abstractId]);

  function callUpdateAbstract(force = false) {
    if (!authorEditorRef.current.hasValidOrganizers()) {
      // Could not validate authors - some errors will be on the page.
      return;
    }
    if (!force && fieldHasErrors(errors, 'text')) {
      // FE validation has error
      return;
    }
    updateAbstract(force, abstractId, status, symposiumId, moveRequested, adminNotes, title, text, presentationType, authors, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Updated abstract.');
        // Update the parent list data (values have changed)
        reloadAbstractList();
        setErrors([]);
        onClose();
      } else {
        setErrors(errors);
      }
    });
  }

  function changeTextField(newValue) {
    // clear errors for the text field
    const newErrors = errors.filter(err => !err.fields.includes('text'));
    const wordCount = countWords(newValue);
    if (wordCount > ABSTRACT_MAX_TEXT_WORDS) {
      newErrors.push({
        'message': 'Text must be ' + ABSTRACT_MAX_TEXT_WORDS + ' words or less. You have ' + wordCount + ' words.',
        'fields': ['text'],
        'can_force': true,
      });
    }
    setErrors(newErrors);
    setText(newValue);
  }

  return (
    <Container fluid className="usacm-container-wide">

      <Row className="mb-3">
        <Col className="col-12">
          Abstract Number
          <span className="ms-3 fw-bold">
              {abstract?.abstract_number_full}
            </span>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12">
          Abstract ID
          <span className="ms-3 fw-bold">
              {abstract?.id}
            </span>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12">
          Status
          {canEditStatus &&
            <Form.Group controlId="formBasicSelect" style={{maxWidth: '200px'}}>
              <Form.Control
                className="form-select"
                as="select"
                value={status || ''}
                onChange={e => setStatus(e.target.value)}
              >
                {statusKeys.map(s => {
                  return <option value={s} key={s}>{statusLabels[s]}</option>
                })}
              </Form.Control>
            </Form.Group>
          }
          {!canEditStatus &&
            <span className="ms-3 fw-bold">
              {getNameForAbstractStatus(conf, abstract)}
            </span>
          }
        </Col>
      </Row>

      {abstract?.move_requested &&
        <Row className="mb-3">
          <Col className="col-12">
            {isStaffOrAdmin &&
              <Form.Check
                type="checkbox"
                label="Move Requested"
                id="move_requested"
                checked={moveRequested}
                onChange={e => setMoveRequested(e.target.checked)}
              />
            }
            {!isStaffOrAdmin && <div>Move Requested</div>}
            {abstract?.move_comment}
          </Col>
        </Row>
      }

      {isStaffOrAdmin &&
        <Row>
          <Col className="mb-4">
            <div>Move to {titleCase(symposiumLabel)}</div>
            <Form.Group controlId="symposium-select">
              <Form.Control
                className="form-select"
                as="select"
                value={symposiumId || ''}
                onChange={e => setSymposiumId(e.target.value ? parseInt(e.target.value, 10) : -1)}
                isInvalid={fieldHasErrors(errors, 'symposium_id')}
              >
                {confSymposia?.map(s => {
                  return <option value={s.id} key={s.id}>{s.symposium_number} - {s.title}</option>
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'symposium_id')}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      }

      {isStaffOrAdmin &&
        <Row>
          <Col className="mb-3">
            <div>Admin Notes</div>
            <Form.Group controlId="text">
              <Form.Control as="textarea"
                            rows={6}
                            placeholder="Enter admin notes here..."
                            name="admin_notes"
                            value={adminNotes}
                            onChange={e => setAdminNotes(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'admin_notes')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'admin_notes')}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      }

      <Row className="mb-3">
        <Col className="col-12">
          Abstract Title
          {isStaffOrAdmin &&
            <Button onClick={() => setTitle(sentenceCase(title))} size="sm" className="ms-3 mb-1">Use Sentence Case</Button>
          }
          <Form.Group controlId="title">
            <Form.Control type="text"
                          placeholder=''
                          name="title"
                          value={title || ''}
                          onChange={e => setTitle(e.target.value)}
                          isInvalid={fieldHasErrors(errors, 'title')}/>
            <Form.Control.Feedback type="invalid">
              {getErrorMessageForField(errors, 'title')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="mb-3">
          <div>Text <span>({ABSTRACT_MAX_TEXT_WORDS} word max)</span></div>
          <Form.Group controlId="text">
            <Form.Control as="textarea"
                          rows={12}
                          placeholder="Enter abstract text..."
                          required
                          name="text"
                          value={text}
                          onChange={e => changeTextField(e.target.value)}
                          isInvalid={fieldHasErrors(errors, 'text')}/>
            <Form.Control.Feedback type="invalid">
              {getErrorMessageForField(errors, 'text')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      {conf?.abstract_use_presentation_type &&
        <Row className="mb-3">
          <Col className="col-12">
            <div>Presentation Type</div>
            <Form.Check
              id="presentation_type_poster"
              type="radio"
              name="presentation_type"
              label="Oral"
              value={ABSTRACT_PRESENTATION_TYPE_ORAL}
              inline
              checked={!presentationType || presentationType === ABSTRACT_PRESENTATION_TYPE_ORAL}
              onChange={e => setPresentationType(e.target.value)}
            />
            <Form.Check
              id="presentation_type_oral"
              type="radio"
              name="presentation_type"
              label="Poster"
              value={ABSTRACT_PRESENTATION_TYPE_POSTER}
              inline
              checked={presentationType === ABSTRACT_PRESENTATION_TYPE_POSTER}
              onChange={e => setPresentationType(e.target.value)}
            />
            {conf.abstract_presentation_type_other &&
              <Form.Check
                id="presentation_type_other"
                type="radio"
                name="presentation_type"
                label={conf.abstract_presentation_type_other}
                value={conf.abstract_presentation_type_other}
                inline
                checked={presentationType === conf.abstract_presentation_type_other}
                onChange={e => setPresentationType(e.target.value)}
              />
            }
          </Col>
        </Row>
      }

      <AuthorOrganizerEditor
        ref={authorEditorRef}
        organizers={authors}
        setOrganizers={setAuthors}
        errors={errors}
        setErrors={setErrors}
        type={TYPE_AUTHOR}
      />

      {anyFieldHasErrors(errors, ['', 'abstract_id']) &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForAllFields(errors, ['', 'abstract_id'])}
          </Col>
        </Row>
      }

      <Row className='mb-3'>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onClose()}>Abstract List</Button>
          <Button onClick={() => callUpdateAbstract()}>Update Abstract</Button>
          <ForceUpdateButton errors={errors} onClick={() => callUpdateAbstract(true)}/>
        </Col>
      </Row>

    </Container>
  );
}
