import {get, post} from "../utils/networkUtils";

/**
 * Calls the /conf/ endpoint and gets the current conference.
 */
export function getCurrentConf(onComplete) {
  get("conf/", onComplete);
}

/**
 * Calls the /conf/users endpoint and gets the current conference.
 */
export function getCurrentConfUsers(onComplete) {
  get("conf/users/", onComplete);
}

/**
 * Gets the users in the current conf
 * Brief means permissions are NOT returned.  This saves a lot o time calculating permissions.
 */
export function getCurrentConfUsersBrief(onComplete) {
  get("conf/users/brief/", onComplete);
}

/**
 * Gets all the email templates for the current conf (requires conf admin permission)
 */
export function getConfEmails(onComplete) {
  get("conf/emails/", onComplete);
}

/**
 * Calls the /conf/create endpoint
 */
export function createConf(host, shortName, longName, onComplete) {
  const data = {
    host: host,
    short_name: shortName,
    long_name: longName,
  };
  post("conf/create/", data, onComplete);
}

/**
 * Deletes the conference in the DB (Don't do this by accident!)
 */
export function deleteConf(confId, onComplete) {
  const data = {
    conf_id: confId,
  };
  post("conf/delete/", data, onComplete);
}

/**
 * Gets a list of all the conferences
 */
export function getAllConf(onComplete) {
  get("conf/list/", onComplete);
}

/**
 * Calls the /conf/update endpoint
 */
export function updateConfGeneral(host, shortName, longName, locationCountry, locationState, locationCity, location,
                                  confStart, confEnd, confTimezone, fromEmail, useRegistration,
                                  onComplete) {
  const data = {
    host: host,
    short_name: shortName,
    long_name: longName,
    location_country: locationCountry,
    location_state: locationState,
    location_city: locationCity,
    location: location,
    conf_start: confStart,
    conf_end: confEnd,
    conf_timezone: confTimezone,
    from_email: fromEmail,
    use_registration: useRegistration,
  };
  post("conf/update/general/", data, onComplete);
}

/**
 * Calls the /conf/update endpoint
 */
export function updateConfTheme(host, logoUrl, logoInvoiceUrl, logoShape, headerUrl, faviconUrl,
                                darkColor, mediumDarkColor, lightColor, highlightColor,
                                cardHeaderText, cardHeaderSecondaryText, cardHeaderBackground,
                                linkColor, primaryText, primaryBackground, primaryHoverText, primaryHoverBackground,
                                secondaryText, secondaryBackground, secondaryHoverText, secondaryHoverBackground,
                                disabledText, disabledBackground,
                                onComplete) {
  const data = {
    host: host,
    logo_url: logoUrl,
    logo_invoice_url: logoInvoiceUrl,
    logo_shape: logoShape,
    header_url: headerUrl,
    favicon_url: faviconUrl,
    dark_color: darkColor,
    medium_dark_color: mediumDarkColor,
    light_color: lightColor,
    highlight_color: highlightColor,
    card_header_text: cardHeaderText,
    card_header_secondary_text: cardHeaderSecondaryText,
    card_header_background: cardHeaderBackground,
    link_color: linkColor,
    primary_text: primaryText,
    primary_background: primaryBackground,
    primary_hover_text: primaryHoverText,
    primary_hover_background: primaryHoverBackground,
    secondary_text: secondaryText,
    secondary_background: secondaryBackground,
    secondary_hover_text: secondaryHoverText,
    secondary_hover_background: secondaryHoverBackground,
    disabled_text: disabledText,
    disabled_background: disabledBackground,
  };
  post("conf/update/theme/", data, onComplete);
}

/**
 * Calls the /conf/update endpoint
 */
export function updateConfAbstract(useSymposia,
                                   useAbstracts,
                                   symposiumDeadline,
                                   hideProposeSymposiumAfterDeadline,
                                   abstractSubmitStart, abstractSubmitEnd,
                                   hideSubmitAbstractAfterEndDate,
                                   abstractApprovalStart, abstractApprovalEnd,
                                   useExtendedAbstract,
                                   extendedAbstractStart, extendedAbstractEnd,
                                   searchableScheduleStart,
                                   abstractNumberPrefix,
                                   abstractUsePresentationType,
                                   abstractPresentationTypeOther,
                                   abstractAuthorCanPresentOnlyOnce,
                                   symposiumSchedulingOpen,
                                   useKeynotes,
                                   allowSingleTimeslotKeynotes,
                                   onComplete) {
  const data = {
    use_symposia: useSymposia,
    use_abstracts: useAbstracts,
    symposium_proposal_deadline: symposiumDeadline,
    hide_propose_symposium_after_deadline: hideProposeSymposiumAfterDeadline,
    abstract_submit_start: abstractSubmitStart,
    abstract_submit_end: abstractSubmitEnd,
    hide_submit_abstract_after_end_date: hideSubmitAbstractAfterEndDate,
    abstract_approval_start: abstractApprovalStart,
    abstract_approval_end: abstractApprovalEnd,
    use_extended_abstract: useExtendedAbstract,
    extended_abstract_start: extendedAbstractStart,
    extended_abstract_end: extendedAbstractEnd,
    searchable_schedule_start: searchableScheduleStart,
    abstract_number_prefix: abstractNumberPrefix,
    abstract_use_presentation_type: abstractUsePresentationType,
    abstract_presentation_type_other: abstractPresentationTypeOther,
    abstract_author_can_present_only_once: abstractAuthorCanPresentOnlyOnce,
    symposium_scheduling_open: symposiumSchedulingOpen,
    use_keynotes: useKeynotes,
    allow_single_timeslot_keynotes: allowSingleTimeslotKeynotes,
  };
  post("conf/update/abstract/", data, onComplete);
}

/**
 * Calls the /conf/update endpoint
 */
export function updateConfLists(useEmphasisArea, useSecondaryEmphasisArea, emphasisAreas, locations, onComplete) {
  const data = {
    use_emphasis_area: useEmphasisArea,
    use_secondary_emphasis_area: useSecondaryEmphasisArea,
    emphasis_areas: emphasisAreas, // an array
    locations: locations,
  };
  post("conf/update/lists/", data, onComplete);
}

/**
 * Updates the conference labels (or a subset of the labels)
 * labels should be key value pairs. eg.  {'some_key':'some-val'... }
 */
export function updateConfLabels(labels, onComplete) {
  post("conf/update/labels/", labels, onComplete);
}

/**
 * Updates an email template for the conference
 */
export function updateConfEmail(key, subject, bodyHtml, onComplete) {
  const data = {
    key: key,
    subject: subject,
    body_html: bodyHtml,
  };
  post("conf/update/email/", data, onComplete);
}

/**
 * Sets the conf short name in the page title (document tab name)
 * @param shortName
 */
export function setPageTitle(shortName) {
  document.title = shortName;
}

/**
 * Gets info for a single user for the conf
 */
export function getConfUser(userId, onComplete) {
  get("conf/user/" + userId + "/", onComplete);
}

/**
 * Updates the permissions for a single user for the conf
 * permissions: list of permission keys that user has eg. "[conf_organizer,conf_admin]"
 */
export function updateConfUserPermissions(userId, permissions, onComplete) {
  const permissionsCsv = permissions.join(",");
  const data = {
    'userid': userId,
    'roles': permissionsCsv,
  };
  post("conf/user/permissions/", data, onComplete);
}

