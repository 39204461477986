import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getCurrentConf, updateConfAbstract} from "../api/ConfApi";
import {getConfLabel, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {hasPermission} from "../api/UserApi";
import {CONF_LABEL_KEY_SYMPOSIUM, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";

export function ConfSettingsAbstract() {
  const {conference} = useContext(UsacmContext);
  const [conf, setConf] = conference;
  const [useSymposia, setUseSymposia] = useState(true);
  const [useAbstracts, setUseAbstracts] = useState(true);
  const [symposiumDeadline, setSymposiumDeadline] = useState('');
  const [hideProposeSymposiumAfterDeadline, setHideProposeSymposiumAfterDeadline] = useState(false);
  const [abstractSubmitStart, setAbstractSubmitStart] = useState('');
  const [abstractSubmitEnd, setAbstractSubmitEnd] = useState('');
  const [hideSubmitAbstractAfterEndDate, setHideSubmitAbstractAfterEndDate] = useState(false);
  const [abstractApprovalStart, setAbstractApprovalStart] = useState('');
  const [abstractApprovalEnd, setAbstractApprovalEnd] = useState('');
  const [useExtendedAbstract, setUseExtendedAbstract] = useState(false);
  const [extendedAbstractStart, setExtendedAbstractStart] = useState('');
  const [extendedAbstractEnd, setExtendedAbstractEnd] = useState('');
  const [abstractNumberPrefix, setAbstractNumberPrefix] = useState('');
  const [searchableScheduleStart, setSearchableScheduleStart] = useState('');
  const [abstractUsePresentationType, setAbstractUsePresentationType] = useState(false);
  const [abstractPresentationTypeOther, setAbstractPresentationTypeOther] = useState('');
  const [abstractAuthorCanPresentOnlyOnce, setAbstractAuthorCanPresentOnlyOnce] = useState(false);
  const [symposiumSchedulingOpen, setSymposiumSchedulingOpen] = useState(false);
  const [useKeynotes, setUseKeynotes] = useState(false);
  const [allowSingleTimeslotKeynotes, setAllowSingleTimeslotKeynotes] = useState(false);
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));

  useEffect(() => {
    if (conf) {
      setUseSymposia(conf.use_symposia);
      setUseAbstracts(conf.use_abstracts);
      setSymposiumDeadline(conf.symposium_proposal_deadline || '');
      setHideProposeSymposiumAfterDeadline(conf.hide_propose_symposium_after_deadline || false);
      setAbstractSubmitStart(conf.abstract_submit_start || '');
      setAbstractSubmitEnd(conf.abstract_submit_end || '');
      setHideSubmitAbstractAfterEndDate(conf.hide_submit_abstract_after_end_date || false);
      setAbstractApprovalStart(conf.abstract_approval_start || '');
      setAbstractApprovalEnd(conf.abstract_approval_end || '');
      setUseExtendedAbstract(conf.use_extended_abstract || false);
      setExtendedAbstractStart(conf.extended_abstract_start || '');
      setExtendedAbstractEnd(conf.extended_abstract_end || '');
      setSearchableScheduleStart(conf.searchable_schedule_start || '')
      setAbstractNumberPrefix(conf.abstract_number_prefix || '');
      setAbstractUsePresentationType(conf.abstract_use_presentation_type || false);
      setAbstractPresentationTypeOther(conf.abstract_presentation_type_other || '');
      setAbstractAuthorCanPresentOnlyOnce(conf.abstract_author_can_present_only_once || false);
      setSymposiumSchedulingOpen(conf.symposium_scheduling_open || false);
      setUseKeynotes(conf.use_keynotes || false);
      setAllowSingleTimeslotKeynotes(conf.allow_single_timeslot_keynotes || false);
    }
  }, [conf]);

  /**
   * This will validate the fields and set the errors object if any errors exist
   * @returns {boolean} true if everything is valid and false if there are any problems
   */
  function validate() {
    const newErrors = []
    if (useSymposia && !useAbstracts) {
      newErrors.push({'message': 'You cannot use ' + symposiumLabel + ' without using abstracts', 'fields': ['use_symposia']});
    }
    setErrors(newErrors);
    return newErrors.length === 0;
  }

  function callUpdateConf(event) {
    event.preventDefault();
    if (!validate()) {
      // Validation errors exist we won't save
      return;
    }
    updateConfAbstract(
      useSymposia,
      useAbstracts,
      symposiumDeadline,
      hideProposeSymposiumAfterDeadline,
      abstractSubmitStart, abstractSubmitEnd,
      hideSubmitAbstractAfterEndDate,
      abstractApprovalStart, abstractApprovalEnd,
      useExtendedAbstract,
      extendedAbstractStart, extendedAbstractEnd,
      searchableScheduleStart,
      abstractNumberPrefix,
      abstractUsePresentationType,
      abstractPresentationTypeOther,
      abstractAuthorCanPresentOnlyOnce,
      symposiumSchedulingOpen,
      useKeynotes,
      (allowSingleTimeslotKeynotes && useKeynotes),  // If not using keynotes this must be false
      (status, data, newErrors) => {
        if (status === 200) {
          showSuccessToast("Conference abstract settings saved");
          getCurrentConf((code, data, errors) => {
            if (code === 200) {
              setConf(data);
            } else {
              console.warn('Error loading conf in settings', errors);
            }
          });
        } else {
          setErrors(newErrors);
        }
      });
  }

  return (
    <Form onSubmit={callUpdateConf}>
      <Container fluid className="usacm-container-narrow">


        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label={"Use " + titleCase(symposiumLabel)}
              id="use_symposia"
              checked={useSymposia}
              onChange={e => isStaffOrAdmin && setUseSymposia(e.target.checked)}
              isInvalid={fieldHasErrors(errors, 'use_symposia')}/>
            <div className="usacm-invalid-feedback">
              {getErrorMessageForField(errors, 'use_symposia')}
            </div>
          </Col>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label="Use Abstracts"
              id="use_abstracts"
              checked={useAbstracts}
              onChange={e => isStaffOrAdmin && setUseAbstracts(e.target.checked)}
              isInvalid={fieldHasErrors(errors, 'use_abstracts')}
            />
            <div className="usacm-invalid-feedback">
              {getErrorMessageForField(errors, 'use_abstracts')}
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="symposium_proposal_deadline">
              <FloatingLabel controlId="symposium_proposal_deadline" label="Symposium Proposal Deadline">
                <Form.Control type="date"
                              placeholder="Deadline"
                              name="symposium_proposal_deadline"
                              value={symposiumDeadline}
                              onChange={e => setSymposiumDeadline(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'symposium_proposal_deadline')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'symposium_proposal_deadline')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label={`Hide 'Propose ${symposiumLabel}' after deadline`}
              id="hide_propose_symposium_after_deadline"
              checked={hideProposeSymposiumAfterDeadline}
              onChange={e => isStaffOrAdmin && setHideProposeSymposiumAfterDeadline(e.target.checked)}
            />
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 col-6">
            <Form.Group controlId="abstract_submit_start">
              <FloatingLabel controlId="abstract_submit_start" label="Abstract Submit Start Date">
                <Form.Control type="date"
                              placeholder="Start Date"
                              name="abstract_submit_start"
                              value={abstractSubmitStart}
                              onChange={e => setAbstractSubmitStart(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'abstract_submit_start')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'abstract_submit_start')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col className="mb-3 col-6">
            <Form.Group controlId="abstract_submit_end">
              <FloatingLabel controlId="abstract_submit_start" label="Abstract Submit End Date">
                <Form.Control type="date"
                              placeholder="End Date"
                              name="abstract_submit_end"
                              value={abstractSubmitEnd}
                              onChange={e => setAbstractSubmitEnd(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'abstract_submit_end')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'abstract_submit_end')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label="Hide 'Submit Abstract' after end date"
              id="hide_submit_abstract_after_end_date"
              checked={hideSubmitAbstractAfterEndDate}
              onChange={e => isStaffOrAdmin && setHideSubmitAbstractAfterEndDate(e.target.checked)}
            />
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 col-6">
            <Form.Group controlId="abstract_approval_start">
              <FloatingLabel controlId="abstract_approval_start" label="Abstract Approval Start Date">
                <Form.Control type="date"
                              placeholder="Start Date"
                              name="abstract_approval_start"
                              value={abstractApprovalStart}
                              onChange={e => setAbstractApprovalStart(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'abstract_approval_start')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'abstract_approval_start')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col className="mb-3 col-6">
            <Form.Group controlId="abstract_approval_end">
              <FloatingLabel controlId="abstract_approval_start" label="Abstract Approval End Date">
                <Form.Control type="date"
                              placeholder="End Date"
                              name="abstract_approval_end"
                              value={abstractApprovalEnd}
                              onChange={e => setAbstractApprovalEnd(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'abstract_approval_end')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'abstract_approval_end')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="searchable_schedule_start">
              <FloatingLabel controlId="searchable_schedule_start" label="Searchable Schedule Start">
                <Form.Control type="date"
                              placeholder="Start Date"
                              name="searchable_schedule_start"
                              value={searchableScheduleStart}
                              onChange={e => setSearchableScheduleStart(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'searchable_schedule_start')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'searchable_schedule_start')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="number-prefix">
              <FloatingLabel controlId="number-prefix" label="Abstract Number Prefix">
                <Form.Control type="text"
                              placeholder="Prefix"
                              name="abstract_number_prefix"
                              value={abstractNumberPrefix}
                              onChange={e => setAbstractNumberPrefix(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'abstract_number_prefix')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'abstract_number_prefix')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label="Use Presentation Type (Oral / Poster)"
              id="abstract_use_presentation_type"
              checked={abstractUsePresentationType}
              onChange={e => isStaffOrAdmin && setAbstractUsePresentationType(e.target.checked)}
            />
          </Col>
        </Row>

        {abstractUsePresentationType &&
          <Row>
            <Col className="mb-3">
              <Form.Group controlId="abstract_presentation_type_other">
                <FloatingLabel controlId="abstract_presentation_type_other" label="Presentation Type Other (Either, Both, Undecided)">
                  <Form.Control type="text"
                                placeholder="Presentation Type Other"
                                name="abstract_presentation_type_other"
                                value={abstractPresentationTypeOther}
                                onChange={e => setAbstractPresentationTypeOther(e.target.value)}
                                readOnly={!isStaffOrAdmin}
                                isInvalid={fieldHasErrors(errors, 'abstract_presentation_type_other')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'abstract_presentation_type_other')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        }

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label="Author can present only once in this conference"
              id="abstract_author_can_present_only_once"
              checked={abstractAuthorCanPresentOnlyOnce}
              onChange={e => isStaffOrAdmin && setAbstractAuthorCanPresentOnlyOnce(e.target.checked)}
            />
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label="Main Organizer Scheduling Allowed"
              id="symposium_scheduling_open"
              checked={symposiumSchedulingOpen}
              onChange={e => isStaffOrAdmin && setSymposiumSchedulingOpen(e.target.checked)}
            />
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label="Use Keynotes"
              id="use_keynotes"
              checked={useKeynotes}
              onChange={e => isStaffOrAdmin && setUseKeynotes(e.target.checked)}
            />
          </Col>
        </Row>

        {useKeynotes &&
          <Row>
            <Col className="mb-3">
              <Form.Check
                type="checkbox"
                label="Allow Single Timeslot Keynotes"
                id="allow_single_timeslot_keynotes"
                checked={allowSingleTimeslotKeynotes}
                onChange={e => isStaffOrAdmin && setAllowSingleTimeslotKeynotes(e.target.checked)}
              />
            </Col>
          </Row>
        }

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label="Use Extended Abstracts"
              id="use_extended_abstract"
              checked={useExtendedAbstract}
              onChange={e => isStaffOrAdmin && setUseExtendedAbstract(e.target.checked)}
            />
          </Col>
        </Row>

        {useExtendedAbstract &&
          <Row>
            <Col className="mb-3 col-6">
              <Form.Group controlId="extended_abstract_start">
                <FloatingLabel controlId="extended_abstract_start" label="Extended Abstract Start Date">
                  <Form.Control type="date"
                                placeholder="Start Date"
                                name="extended_abstract_start"
                                value={extendedAbstractStart}
                                onChange={e => setExtendedAbstractStart(e.target.value)}
                                readOnly={!isStaffOrAdmin}
                                isInvalid={fieldHasErrors(errors, 'extended_abstract_start')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'extended_abstract_start')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col className="mb-3 col-6">
              <Form.Group controlId="extended_abstract_end">
                <FloatingLabel controlId="extended_abstract_start" label="Extended Abstract End Date">
                  <Form.Control type="date"
                                placeholder="End Date"
                                name="extended_abstract_end"
                                value={extendedAbstractEnd}
                                onChange={e => setExtendedAbstractEnd(e.target.value)}
                                readOnly={!isStaffOrAdmin}
                                isInvalid={fieldHasErrors(errors, 'extended_abstract_end')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'extended_abstract_end')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        }

        {fieldHasErrors(errors, '') &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
        }

        <Row>
          <Col className="text-center mb-3">
            {isStaffOrAdmin &&
              <Button type="submit" className="ms-3 me-3">Update Conference</Button>
            }
          </Col>
        </Row>

      </Container>
    </Form>
  );

}
